/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef } from "react";
import { extend, useFrame } from "@react-three/fiber";
import { ShaderMaterial, Vector2 } from "three";
import fragmentShader from "./glsl/domino.fragment";
import vertexShader from "./glsl/domino.vertex";

// Extend the ShaderMaterial so it can be used in the JSX
extend({ CustomShaderMaterial: ShaderMaterial });

const DominoField = () => {
  const shaderRef = useRef();
  const planeSize = 2;

  useFrame(({ clock }) => {
    const time = clock.elapsedTime;
    shaderRef.current.uniforms.time.value = time;
  });

  // Animate the size uniform based on time and other factors
  const size = Math.sin(performance.now() * 0.001) * 0.5 + 0.5;

  return (
    <mesh>
      <planeGeometry args={[planeSize, planeSize]} />
      <customShaderMaterial
        ref={shaderRef}
        attach="material"
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        uniforms={{
          time: { value: 0 },
          screenSize: {
            value: new Vector2(window.innerWidth, window.innerHeight)
          },
          size: { value: size }
        }}
      />
    </mesh>
  );
};

// extend({ CustomShaderMaterial: ShaderMaterial });

// const DominoField = () => {
//   const shaderRef = useRef();

//   const numCards = 100;
//   const cardPositions = new Float32Array(numCards * 3);
//   const cardIndices = new Float32Array(numCards);

//   for (let i = 0; i < numCards; i++) {
//     const pos = new Vector3(Math.random() * 5, Math.random() * 5, 0);
//     pos.toArray(cardPositions, i * 3);
//     cardIndices[i] = i;
//   }

//   const updateShader = (shader, time) => {
//     shader.uniforms.time.value = time;
//   };

//   useFrame(({ clock }) => {
//     updateShader(shaderRef.current, clock.elapsedTime);
//   });

//   return (
//     <group position={[0, 0, 1]}>
//       {/* <mesh>
//         <planeBufferGeometry args={[4, 4, 1]} />
//       </mesh> */}

//       <planeGeometry args={[4, 4, 1, 1]} attach="geometry">
//         <bufferAttribute
//           attachObject={[`attributes`, `startPosition`]}
//           array={cardPositions}
//           count={numCards}
//           itemSize={10}
//         />
//         <bufferAttribute
//           attachObject={[`attributes`, `cardIndex`]}
//           array={cardIndices}
//           count={numCards}
//           itemSize={5}
//         />
//       </planeGeometry>

//       <customShaderMaterial
//         ref={shaderRef}
//         attach="material"
//         vertexShader={vertexShader}
//         fragmentShader={fragmentShader}
//         uniforms={{
//           time: { value: 0 },
//           windStrength: { value: 1.0 },
//           color: { value: new Vector3(0.2, 0.4, 0.6) }
//         }}
//       />
//     </group>
//   );
// };

export default DominoField;
