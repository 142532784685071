/* eslint-disable react/jsx-props-no-spreading */

import React from "react";

// Icon library
import { ReactComponent as ArrowDown } from "~assets/svg/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "~assets/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "~assets/svg/arrow-right.svg";
import { ReactComponent as LookingGlass } from "~assets/svg/looking-glass.svg";
import { ReactComponent as Minus } from "~assets/svg/minus.svg";
import { ReactComponent as PlayCircle } from "~assets/svg/play-circle.svg";
import { ReactComponent as Plus } from "~assets/svg/plus.svg";
import { ReactComponent as X } from "~assets/svg/x.svg";

//
import { ReactComponent as Download } from "~assets/svg/download.svg";

const svgs = {
  // Library
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  LookingGlass,
  Minus,
  PlayCircle,
  Plus,
  X,
  // ... all icons as required
  Download
};

const SVG = ({ svg, ...props }) => {
  /** ============================================================================
   * @component
   * Used to render SVG icons
   *
   * @param {string}     svg     Name of desired SVG, must be imported first
   *
   * @return {node}
   */

  if (!svgs[svg]) return null;

  const SVGElement = svgs[svg];

  return <SVGElement {...props} aria-hidden />;
};

export default SVG;
