import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import gsap from "gsap";
import cn from "classnames";
import { useScroll } from "~hooks";
import { Grid, HTMLVideo, Image } from "~components";
import * as styles from "./styles.module.scss";

const SimpleVideo = ({
  id,
  className,
  data: { backgroundColor, videoURL }
}) => {
  const [ref, inView] = useInView({
    threshold: 0.2
  });

  const { scrollTop } = useScroll();
  //

  const overflowRef = useRef();
  const mediaRef = useRef();

  //

  // useEffect(() => {
  //   const offsetRef = overflowRef.current;
  //   const mediaElement = mediaRef.current;

  //   gsap.to(mediaElement, {
  //     y: (offsetRef.offsetTop - scrollTop) * 0.05,
  //     ease: `none`,
  //     duration: 0
  //   });
  // }, [inView, scrollTop]);

  //

  if (!videoURL) {
    return null;
  }

  return (
    <div ref={ref}>
      <section
        ref={overflowRef}
        sliceid={id}
        className={cn(className, styles.container)}
        style={{ background: backgroundColor?.hex || `transparent` }}
      >
        <Grid>
          <div ref={mediaRef} className={styles.media}>
            <HTMLVideo
              className={styles.video}
              src={videoURL}
              autoPlay
              muted
              loop
              playsInline
            />
          </div>
        </Grid>
      </section>
    </div>
  );
};

export default SimpleVideo;
