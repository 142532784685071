/* eslint-disable react/jsx-props-no-spreading */

import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { DominoField, FluidScrollSection, WaveImage } from "~components";
import { useScroll } from "~hooks";

import * as styles from "./styles.module.scss";

const FluidScroll = ({ children }) => {
  // ---------------------------------------------------------------------------
  // hooks

  const { scrollTop } = useScroll();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const scrollRef = useRef();

  // ---------------------------------------------------------------------------
  // lifecycle

  //

  // ---------------------------------------------------------------------------
  // render

  // todo: look into drei / ScrollControls

  const sections = children.map((child, childIndex) => {
    const ref = useRef();

    const innerJSX = <Html fullscreen>{child}</Html>;

    //

    // const type = child?.props?.data?._type;

    // let innerJSX = <Html fullscreen>{child}</Html>;

    // switch (type?.toLowerCase()) {
    //   case `fullvideo`:
    //     innerJSX = <DominoField />;
    //     break;
    // case `simpleimage`:
    //   innerJSX = (
    //     <WaveImage
    //       imageUrl={
    //         child.props.data.image.asset.gatsbyImageData.images.fallback.src
    //       }
    //     />
    //   );
    //   break;

    //   default:
    //     break;
    // }

    return {
      ref,
      jsx: child,
      jsx3d: (
        <FluidScrollSection
          index={childIndex}
          scrollRef={scrollRef}
          sectionRef={ref}
          scrollTop={scrollTop}
        >
          {(data) => innerJSX}
        </FluidScrollSection>
      )
    };
  });

  return (
    <>
      <div className={styles.background} />

      <div className={styles.canvas}>
        <Canvas shadowMap camera={{ zoom: 10, position: [0, 0, 1] }}>
          <ambientLight intensity={1} />

          <pointLight
            position={[0, 10, 30]}
            rotation={[0, 90, 0]}
            intensity={1}
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
            castShadow
          />

          <Suspense>
            <group scale={[1, 1, 1]}>
              <DominoField />
            </group>
          </Suspense>
        </Canvas>
      </div>

      {/* pagebuilder: */}
      {/* <div className={styles.canvas}>
        <Canvas
          orthographic
          shadowMap
          camera={{ zoom: 100, position: [0, 0, 10] }}
        >
          <ambientLight intensity={1} />

          <pointLight
            position={[0, 10, 30]}
            rotation={[0, 90, 0]}
            intensity={1}
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
            castShadow
          />

          <Suspense>
            {sections.map(({ jsx3d }) => jsx3d)}
          </Suspense>
        </Canvas>
      </div>

      <div ref={scrollRef} className={styles.scrollArea}>
        {sections.map(({ jsx, ref }) => (
          <div ref={ref}>{jsx}</div>
        ))}
      </div> */}
    </>
  );
};

export default FluidScroll;
