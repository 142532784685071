import { useEffect, useState } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import useWindowDimensions from "./useWindowDimensions";

export const useFluidScrollSection = ({
  index,
  groupRef,
  sectionRef,
  scrollRef,
  scrollTop
}) => {
  const { windowSize } = useWindowDimensions();
  const [inView, setInView] = useState(false);

  useFrame(() => {
    if (!sectionRef?.current) {
      return;
    }

    // current Y position of the mesh containing the section HTML
    const currentY = groupRef.current.position.y;

    // information about the section to which this hook is attached
    const sectionRefBounds = sectionRef.current.getBoundingClientRect();
    const sectionDistanceFromTop = sectionRefBounds.top;

    groupRef.current.position.y = THREE.MathUtils.lerp(
      currentY,
      sectionDistanceFromTop * -0.1,
      0.1
    );
  });

  return {
    groupRef,
    sectionRef,
    index,
    inView
  };
};

export default useFluidScrollSection;
