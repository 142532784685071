// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--eZ57C";
export var appearDown = "styles-module--appear-down--M9X2v";
export var appearLeft = "styles-module--appear-left--HBJ3q";
export var appearRight = "styles-module--appear-right--cSuQ6";
export var appearUp = "styles-module--appear-up---tQgh";
export var container = "styles-module--container--iVlY3";
export var content = "styles-module--content--5zb4c";
export var contentVisible = "styles-module--contentVisible--SGL2y";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--iPp94";
export var heading = "styles-module--heading--MJ4yJ";
export var headingVisible = "styles-module--headingVisible--LclkL";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var separator = "styles-module--separator--y4mav";
export var separatorVisible = "styles-module--separatorVisible--wyqPd";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";