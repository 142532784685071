import React, { useEffect, useRef } from "react";
import { Button } from "~components";
import {
  useApp,
  useKeyPress,
  useSidebarLinks,
  useSanitySettings
} from "~hooks";
import cn from "classnames";
import { ReactComponent as ArrowRight } from "~assets/svg/arrow.svg";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * Lowermost buttons allowing for site navigation.
 */
const Nav = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { pathname } = useApp();
  const { getPrevLink, getNextLink } = useSidebarLinks();
  const { settings } = useSanitySettings();

  const leftButtonRef = useRef();
  const rightButtonRef = useRef();

  const leftPressed = useKeyPress(`ArrowLeft`);
  const rightPressed = useKeyPress(`ArrowRight`);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (leftPressed && leftButtonRef?.current) {
      leftButtonRef.current.click();
    }
  }, [leftPressed]);

  useEffect(() => {
    if (rightPressed && rightButtonRef?.current) {
      rightButtonRef.current.click();
    }
  }, [rightPressed]);

  // ---------------------------------------------------------------------------
  // render

  if (!pathname || pathname === `/` || !settings?.footer?.active) {
    return null;
  }

  const prevButton = getPrevLink(pathname);
  const nextButton = getNextLink(pathname);

  return (
    <nav id="nav" className={styles.container}>
      {prevButton && (
        <div
          className={cn(styles.navButton, {
            [styles.navButtonAlone]: !nextButton
          })}
        >
          <Button
            innerRef={leftButtonRef}
            className={styles.navButtonInner}
            to={prevButton.url}
            transparent
          >
            <ArrowRight className={styles.arrowLeft} fill="white" />

            <span className="button-text">{prevButton.label}</span>
          </Button>
        </div>
      )}

      {nextButton && (
        <div
          className={cn(styles.navButton, {
            [styles.navButtonAlone]: !prevButton
          })}
        >
          <Button
            innerRef={rightButtonRef}
            className={styles.navButtonInner}
            to={nextButton.url}
            transparent
          >
            <span className="button-text">{nextButton.label}</span>

            <ArrowRight className={styles.arrowRight} fill="white" />
          </Button>
        </div>
      )}
    </nav>
  );
};

export default Nav;
