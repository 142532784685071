import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import gsap from "gsap";
import cn from "classnames";
import { useScroll } from "~hooks";
import { Grid, HTMLVideo } from "~components";

import * as styles from "./styles.module.scss";

const FullVideo = ({
  id,
  className,
  data: { backgroundColor, heading, videoURL }
}) => {
  // hooks

  const [ref, inView] = useInView({
    threshold: 0.3
  });

  const { scrollTop } = useScroll();

  //

  const mediaRef = useRef();
  const textRef = useRef();

  //

  const headingLines = heading.split(`\n`);

  //

  useEffect(() => {
    const mediaElement = mediaRef.current;

    gsap.to(mediaElement, {
      y: scrollTop * 0.2,
      ease: `none`,
      duration: 0
    });

    const textElement = textRef.current;

    gsap.to(textElement, {
      y: -scrollTop * 0.075,
      ease: `none`,
      duration: 0
    });
  }, [scrollTop]);

  //

  if (!videoURL || videoURL === ``) {
    return null;
  }

  return (
    <section
      ref={ref}
      sliceid={id}
      className={cn(className, styles.container, {
        [styles.containerHidden]: !inView
      })}
      style={{ background: backgroundColor?.hex || `transparent` }}
    >
      <figure ref={mediaRef} className={styles.media}>
        <HTMLVideo
          className={styles.video}
          src={videoURL}
          autoPlay
          muted
          loop
          playsInline
        />
      </figure>

      <div ref={textRef} className={styles.text}>
        <Grid>
          <div className={styles.textContent}>
            <h1 className="d1">
              {headingLines.map((line, lineIndex) => {
                const key = `heading-line-${lineIndex}`;

                return (
                  <span key={key}>
                    <span
                      className={styles.textContentSpan}
                      style={{
                        animationDelay: `${(lineIndex + 1) * 150 + 300}ms`
                      }}
                    >
                      {line}
                    </span>
                  </span>
                );
              })}
            </h1>
          </div>
        </Grid>
      </div>
    </section>
  );
};

export default FullVideo;
