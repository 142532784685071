const shader = `
  uniform float time;
  uniform vec2 screenSize;

  attribute float size;
  varying float vSize;

  void main() {
      vSize = size;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

export default shader;
