// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--kZ4yL";
export var appearDown = "styles-module--appear-down--mwpcC";
export var appearLeft = "styles-module--appear-left--mqdlO";
export var appearRight = "styles-module--appear-right--Kz027";
export var appearUp = "styles-module--appear-up--9IOpQ";
export var container = "styles-module--container--zOfvO";
export var content = "styles-module--content--8T0pM";
export var contentVisible = "styles-module--contentVisible--5A86C";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";