import React from "react";
import { BorderArticle } from "~slices";

import * as styles from "./styles.module.scss";

const Footer = () => (
  <footer className={styles.container}>
    <BorderArticle
      asFooter
      data={{
        bordered: true,
        headingStyle: `b2`,
        heading: `©CMC 2023`,
        sliceConfig: {
          backgroundColor: {
            hex: `#050532`
          },
          textColor: {
            hex: `#ffffff`
          }
        }
      }}
    />
  </footer>
);

export default Footer;
