import React, { useEffect } from "react";
import cn from "classnames";
import { Link, SVG, SidebarGroup } from "~components";
import { useApp, useSidebarLinks, useBreakpoints } from "~hooks";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./styles.module.scss";

const MobileMenu = ({ settings }) => {
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useApp();
  const { groups } = useSidebarLinks();
  const { breakpoints } = useBreakpoints();

  const breakpoint = breakpoints.smallDesktop;

  const openMenu = () => setIsMobileMenuOpen(true);
  const closeMenu = () => setIsMobileMenuOpen(false);

  useEffect(() => {
    if (breakpoint) {
      setIsMobileMenuOpen(false);
    }
  }, [breakpoint]);

  return (
    <>
      <header className={styles.header}>
        <Link to="/" className={styles.homeLink}>
          {settings?.sidebar?.logo?.asset?.gatsbyImageData ? (
            <GatsbyImage
              style={{ height: `100%` }}
              imgStyle={{ objectPosition: `left`, objectFit: `contain` }}
              image={settings.sidebar.logo.asset.gatsbyImageData}
            />
          ) : (
            <h3 className="b3">XXX DBP</h3>
          )}
        </Link>
        <button onClick={openMenu} type="button" className={styles.hamburger}>
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </button>
      </header>
      <nav className={cn(styles.nav, { [styles.open]: isMobileMenuOpen })}>
        <div className={styles.header}>
          <button className={styles.xButton} type="button" onClick={closeMenu}>
            <SVG svg="X" className={styles.xIcon} />
          </button>
        </div>
        <div className={styles.navItems}>
          {groups !== null &&
            Object.keys(groups).map((groupKey) => {
              const group = groups[groupKey];

              return (
                <SidebarGroup
                  key={`sidebar-group-${groupKey}`}
                  data={{ name: groupKey, links: group }}
                />
              );
            })}
        </div>
      </nav>
    </>
  );
};

export default MobileMenu;
