/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef, useState } from "react";
import * as THREE from "three";
import { Html, Image, shaderMaterial } from "@react-three/drei";
import { extend, useFrame, useLoader } from "@react-three/fiber";

const WaveShaderMaterial = shaderMaterial(
  // Uniform
  {
    uBrightness: 1.7,
    uTime: 0,
    uColor: new THREE.Color(0.0, 0.0, 0.0),
    uTexture: new THREE.Texture()
  },
  // Vertex Shader
  /* glsl */ `
    precision mediump float;
 
    varying vec2 vUv;
    varying float vWave;

    uniform float uTime;

    
  vec3 mod289(vec3 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec4 mod289(vec4 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec4 permute(vec4 x) {
      return mod289(((x*34.0)+1.0)*x);
  }

  vec4 taylorInvSqrt(vec4 r)
  {
    return 1.79284291400159 - 0.85373472095314 * r;
  }

  float snoise3(vec3 v)
    {
    const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

    vec3 i  = floor(v + dot(v, C.yyy) );
    vec3 x0 =   v - i + dot(i, C.xxx) ;

    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min( g.xyz, l.zxy );
    vec3 i2 = max( g.xyz, l.zxy );

    vec3 x1 = x0 - i1 + C.xxx;
    vec3 x2 = x0 - i2 + C.yyy; // 2.0*C.x = 1/3 = C.y
    vec3 x3 = x0 - D.yyy;      // -1.0+3.0*C.x = -0.5 = -D.y

    i = mod289(i);
    vec4 p = permute( permute( permute(
              i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
            + i.y + vec4(0.0, i1.y, i2.y, 1.0 ))
            + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

    float n_ = 0.142857142857; // 1.0/7.0
    vec3  ns = n_ * D.wyz - D.xzx;

    vec4 j = p - 49.0 * floor(p * ns.z * ns.z);  //  mod(p,7*7)

    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);

    vec4 b0 = vec4( x.xy, y.xy );
    vec4 b1 = vec4( x.zw, y.zw );

    //vec4 s0 = vec4(lessThan(b0,0.0))*2.0 - 1.0;
    //vec4 s1 = vec4(lessThan(b1,0.0))*2.0 - 1.0;
    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));

    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

    vec3 p0 = vec3(a0.xy,h.x);
    vec3 p1 = vec3(a0.zw,h.y);
    vec3 p2 = vec3(a1.xy,h.z);
    vec3 p3 = vec3(a1.zw,h.w);

    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;

    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1),
                                  dot(p2,x2), dot(p3,x3) ) );
    }

    void main() {
      vUv = uv;

      vec3 pos = position;

      float noiseFreq = 0.05;
      float noiseAmp = 0.004;

      vec3 noisePos = vec3(pos.x * noiseFreq + uTime / 0.5, pos.y / -4.0, pos.z);
      
      pos.z += snoise3(noisePos) * noiseAmp;
      
      vWave = pos.z;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);  
    }
  `,
  // Fragment Shader
  /* glsl */ `
    precision mediump float;

    uniform vec3 uColor;
    uniform float uTime;
    uniform sampler2D uTexture;
    uniform float uBrightness;
    
    varying vec2 vUv;
    varying float vWave;
    
    void main() {
      float wave = -tan(vWave * 0.4) * 2.0;
      
      vec3 color = vec3(0.0);
      float blurAmount = 0.02; // Adjust this value to control the amount of blur
      float increment = blurAmount / 10.0;
      
      // Sample neighboring pixels and take their average
      for (float i = -blurAmount; i <= blurAmount; i += increment) {
        for (float j = -blurAmount; j <= blurAmount; j += increment) {
          vec2 offset = vec2(i, j);
          color += uBrightness * texture2D(uTexture, vUv + wave + offset).rgb; // multiply sampled color values by uBrightness
        }
      }
      
      // Divide by the number of samples to get the average color
      color /= pow((2.0 * blurAmount / increment + 1.0), 2.0);
      
      gl_FragColor = vec4(color, 1.0);
    }
  `
);

extend({ WaveShaderMaterial });

const WaveImage = ({ index, imageUrl, position = [0, 0, 0], scale = 1 }) => {
  console.log(`imageUrl: `, imageUrl);

  const ref = useRef();

  // const [image] = useLoader(THREE.TextureLoader, [imageUrl]);
  const [image] = useLoader(THREE.TextureLoader, [`/images/test-image.webp`]);

  // ---------------------------------------------------------------------------
  // lifecycle

  useFrame(({ clock }) => {
    ref.current.uTime = clock.getElapsedTime();
  });

  // ---------------------------------------------------------------------------
  // render

  // <Image scale={[26, 33, 0]} url={imageUrl} opacity={0.33} transparent />

  return (
    <group position={[0, 0, 1]}>
      <mesh>
        <planeBufferGeometry args={[1, 1, 1]} />
        <waveShaderMaterial
          ref={ref}
          side={THREE.DoubleSide}
          uTexture={image}
        />
      </mesh>
    </group>
  );
};

export default WaveImage;
