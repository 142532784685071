import React from "react";
import cn from "classnames";

import { BlockContent, Grid, Image, SliceConfig } from "~components";

import * as styles from "./styles.module.scss";

const FeatureImage = ({
  id,
  className,
  data: { _rawText, image, sliceConfig }
}) => {
  if (!image) {
    return null;
  }

  return (
    <section sliceid={id} className={cn(className, styles.container)}>
      <SliceConfig config={sliceConfig}>
        <Grid>
          {_rawText && (
            <div className={[styles.rawBody]}>
              <div className={styles.lozenge}>
                <p className="b1">Proposition</p>
              </div>

              <BlockContent blocks={_rawText} />
            </div>
          )}
        </Grid>

        <figure className={styles.figure}>
          <Image className={styles.image} image={image} />
        </figure>
      </SliceConfig>
    </section>
  );
};

export default FeatureImage;
