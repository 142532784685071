import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "~hooks/useSiteMetadata";

const SEO = ({ pageTitle }) => {
  const meta = useSiteMetadata();
  const siteTitle = meta.title;
  const titleText =
    pageTitle === `Home` ? siteTitle : `${pageTitle} | ${siteTitle}`;

  return (
    <>
      <Helmet>
        <title>{titleText}</title>
        <link rel="icon" type="image/png" href="/images/favicon.png" />
      </Helmet>
    </>
  );
};

export default SEO;
