import React from "react";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import { Grid, SliceConfig } from "~components";
import { hexToRGB } from "~utils/helpers";
import * as styles from "./styles.module.scss";

const ColorGrid = ({ id, className, data: { items, sliceConfig } }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const [ref, inView] = useInView({
    threshold: 0
  });

  return (
    <section ref={ref} sliceid={id} className={cn(className, styles.container)}>
      <SliceConfig config={sliceConfig}>
        <Grid>
          <div className={styles.gridItems}>
            {items.map(({ name, display, fontColor }, itemIndex) => {
              const rgb = hexToRGB(fontColor.hex);

              return (
                <article
                  className={styles.gridItem}
                  style={{
                    color: display === `Light` ? `#fff` : `#050532`
                  }}
                >
                  <div
                    className={cn(styles.gridItemInfo, {
                      [styles.gridItemInfoActive]: inView
                    })}
                    style={{
                      animationDelay: `${150 * (itemIndex + 1)}ms`
                    }}
                  >
                    <h4 className="b2">{name}</h4>

                    <div>
                      <h4 className="b2">{fontColor.hex}</h4>
                      <h4 className="b2">
                        R {rgb.r} G {rgb.g} B {rgb.b}
                      </h4>
                      <h4 className="b2">{fontColor.title}</h4>
                    </div>
                  </div>

                  <div
                    className={cn(styles.gridItemBackground, {
                      [styles.gridItemBackgroundActive]: inView
                    })}
                    style={{
                      animationDelay: `${150 * (itemIndex + 1)}ms`,
                      background: fontColor.hex,
                      border: `1px solid ${
                        fontColor?.hex === `#050532` ? `#37375b` : `transparent`
                      }`
                    }}
                  />
                </article>
              );
            })}
          </div>
        </Grid>
      </SliceConfig>
    </section>
  );
};

export default ColorGrid;
