import React, { useRef } from "react";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import typography from "~data/typography";
import { Grid, SliceConfig } from "~components";

import * as styles from "./styles.module.scss";

const TypeTable = ({ id, className, data: { sliceConfig } }) => {
  const tablesRefs = useRef([]);

  return (
    <section sliceid={id} className={[className, styles.container].join(` `)}>
      <SliceConfig config={sliceConfig}>
        <Grid>
          {typography.map(({ min, max, styles: breakpointStyles }) => (
            <ul key={`type-table-${min}-${max}`} className={styles.list}>
              <li className={cn(styles.listHeading)}>
                <p className={[styles.listHeadingStyle, `caption`].join(` `)}>
                  {min}-{max === -1 ? `1920px+` : `${max}px`} Styles
                </p>

                <p className={[styles.listHeadingSize, `caption`].join(` `)}>
                  Font-size
                </p>
                <p className={[styles.listHeadingHeight, `caption`].join(` `)}>
                  Line-height
                </p>
                <p className={[styles.listHeadingSpacing, `caption`].join(` `)}>
                  Letter-spacing
                </p>
              </li>

              {breakpointStyles.map(
                (
                  { id: styleId, className: listItemClassName, attributes },
                  rowIndex
                ) => {
                  const [ref, inView] = useInView({
                    threshold: 0
                  });

                  return (
                    <li
                      id={`type-table-row-${styleId}`}
                      key={`type-table-row-${styleId}`}
                      ref={(el) => {
                        ref(el);
                        tablesRefs.current[rowIndex] = el;
                      }}
                      className={cn(styles.listItem, {
                        [styles.listItemVisible]: inView
                      })}
                    >
                      <p
                        className={[
                          styles.listItemStyle,
                          listItemClassName
                        ].join(` `)}
                        style={{ ...attributes }}
                      >
                        {styleId}
                      </p>

                      <p className={[styles.listItemSize, `caption`].join(` `)}>
                        {attributes.fontSize}
                      </p>
                      <p
                        className={[styles.listItemHeight, `caption`].join(` `)}
                      >
                        {attributes.lineHeight}
                      </p>
                      <p
                        className={[styles.listItemSpacing, `caption`].join(
                          ` `
                        )}
                      >
                        {attributes.letterSpacing}
                      </p>
                    </li>
                  );
                }
              )}
            </ul>
          ))}
        </Grid>
      </SliceConfig>
    </section>
  );
};

export default TypeTable;
