// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--dKyBd";
export var appearDown = "styles-module--appear-down--BksNj";
export var appearLeft = "styles-module--appear-left--L0WU2";
export var appearRight = "styles-module--appear-right--NKju-";
export var appearUp = "styles-module--appear-up--NA+4B";
export var container = "styles-module--container--9cyiL";
export var desktop = "1340px";
export var giant = "2200px";
export var gridItem = "styles-module--gridItem--M+2Lc";
export var gridItemBackground = "styles-module--gridItemBackground--V5gZi";
export var gridItemBackgroundActive = "styles-module--gridItemBackgroundActive--2Kodd";
export var gridItemInfo = "styles-module--gridItemInfo--e-k5V";
export var gridItemInfoActive = "styles-module--gridItemInfoActive--UZe7V";
export var gridItems = "styles-module--gridItems--t4t7m";
export var infoAppear = "styles-module--info-appear--cWHEc";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tileAppear = "styles-module--tile-appear--pyqxM";