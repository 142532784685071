// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--qWQvE";
export var appearDown = "styles-module--appear-down--Oc2h1";
export var appearLeft = "styles-module--appear-left--Ot4Ud";
export var appearRight = "styles-module--appear-right--Id6q0";
export var appearUp = "styles-module--appear-up--oGio0";
export var container = "styles-module--container--KRkQV";
export var containerHidden = "styles-module--containerHidden--f6vkm";
export var desktop = "1340px";
export var fullvideoTextAppear = "styles-module--fullvideo-text-appear--hFA-Y";
export var giant = "2200px";
export var image = "styles-module--image--V34Lo";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--XscpD";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--ZHjf3";
export var textContent = "styles-module--textContent--pELhl";
export var textContentSpan = "styles-module--textContentSpan--S-8ZI";
export var video = "styles-module--video--piHjy";