import React from "react";
import { Image, Link, SidebarGroup, MobileMenu } from "~components";
import { useSanitySettings, useSidebarLinks } from "~hooks";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * DBP Sidebar.
 */
const Sidebar = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { groups } = useSidebarLinks();
  const { settings } = useSanitySettings();

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <MobileMenu settings={settings} />

      <nav className={styles.desktopContainer}>
        <Link to="/" className={styles.logoLink}>
          {settings?.sidebar?.logo?.asset?.gatsbyImageData ? (
            <Image className={styles.logoImage} image={settings.sidebar.logo} />
          ) : (
            <h3 className="b3">XXX DBP</h3>
          )}
        </Link>
        <div className={styles.navItems}>
          {groups !== null &&
            Object.keys(groups).map((groupKey) => {
              const group = groups[groupKey];

              return (
                <SidebarGroup
                  key={`sidebar-group-${groupKey}`}
                  data={{ name: groupKey, links: group }}
                />
              );
            })}
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
