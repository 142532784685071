import React, { useEffect, useState } from "react";
import cn from "classnames";
import gsap from "gsap";
import { useKeyPress } from "~hooks";
import { Grid, Link } from "~components";
import listBlocks from "~data/nav";

import { ReactComponent as Logo } from "~assets/svg/logo.svg";
import * as styles from "./styles.module.scss";

const Header = () => {
  // ---------------------------------------------------------------------------
  // hooks

  const escapePressed = useKeyPress(`Escape`);
  // const { scrollTop } = useScroll();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [menuActive, setMenuActive] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (menuActive) {
      gsap.fromTo(
        `.header-list-item`,
        {
          y: `38px`
        },
        {
          y: `0`,
          duration: 0.75,
          ease: `power2`,
          delay: 0.2,
          stagger: {
            each: 0.035,
            from: `start`
          }
        }
      );
    } else {
      gsap.to(`.header-list-item`, {
        y: `38px`,
        duration: 0.2,
        ease: `power2`
      });
    }
  }, [menuActive]);

  // useEffect(() => {
  //   setMenuActive(false);
  // }, [scrollTop]);

  useEffect(() => {
    if (escapePressed) {
      setMenuActive(false);
    }
  }, [escapePressed]);

  // ---------------------------------------------------------------------------
  // render

  const innerContent = (
    <Grid
      className={cn(styles.grid, {
        [styles.light]: !menuActive ? styles.light : null,
        [styles.dark]: menuActive ? styles.dark : null
      })}
    >
      <div className={cn(styles.logoContainer)}>
        <Link to="/">
          <Logo className={cn(styles.logo)} />
        </Link>
      </div>

      <div className={cn(styles.menuContainer)}>
        <button
          type="button"
          className={cn(styles.menu)}
          onClick={() => setMenuActive(!menuActive)}
        >
          <div className={cn(styles.menuIcon)}>
            <div className={cn(styles.menuLine)} />
            <div className={cn(styles.menuLine)} />
            <div className={cn(styles.menuLine)} />
          </div>
        </button>
      </div>
    </Grid>
  );

  return (
    <header className={cn(styles.container)}>
      {innerContent}

      <div className={cn(styles.submenuContainer)}>
        <nav
          className={cn(styles.submenu, {
            [styles.submenuActive]: menuActive ? styles.submenuActive : null
          })}
        >
          <Grid>
            {Object.keys(listBlocks).map((key) => {
              const list = listBlocks[key];

              return (
                <div
                  className={cn(styles.submenuList)}
                  key={`header-submenu-${key}`}
                >
                  <h4 className={cn(styles.listLabel, `caption`)}>{key}</h4>

                  <ul>
                    {list.map(({ active, label, link }, index) => {
                      const className = active
                        ? cn(styles.listItem, `h2`)
                        : cn(styles.listItem, styles.listItemDisabled, `h2`);

                      return (
                        <li
                          className={className}
                          key={`header-submenu-${key}-item-${index}`}
                        >
                          <Link
                            to={link}
                            className="header-list-item"
                            style={{ transitionDelay: `${index * 0.1}ms` }}
                            onClick={() => setMenuActive(false)}
                          >
                            <span>{label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </Grid>
        </nav>
      </div>
    </header>
  );
};

export default Header;
