// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--CEHcP";
export var appearDown = "styles-module--appear-down--DZv3R";
export var appearLeft = "styles-module--appear-left--SqI9r";
export var appearRight = "styles-module--appear-right--OkT1+";
export var appearUp = "styles-module--appear-up--UgFr7";
export var container = "styles-module--container--l3EUf";
export var desktop = "1340px";
export var giant = "2200px";
export var gridItem = "styles-module--gridItem--D1mZM";
export var gridItemBackground = "styles-module--gridItemBackground--q1NDG";
export var gridItemBackgroundActive = "styles-module--gridItemBackgroundActive--la1Qt";
export var gridItemInfo = "styles-module--gridItemInfo--6uFSF";
export var gridItemInfoActive = "styles-module--gridItemInfoActive--WbbdP";
export var gridItemLarge = "styles-module--gridItemLarge--Vaicz";
export var gridItems = "styles-module--gridItems--7QTl-";
export var infoAppear = "styles-module--info-appear--HPnLm";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tileAppear = "styles-module--tile-appear--W839+";