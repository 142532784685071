const shader = `
  uniform float time;
  uniform vec2 screenSize;

  varying float vSize;

  float box(vec2 uv, vec2 boxSize, float borderSize) {
      uv = uv * 2.0 - 1.0;
      uv = abs(uv) - boxSize;
      return step(max(uv.x, uv.y), -borderSize);
  }

  void main() {
      vec2 uv = gl_FragCoord.xy / screenSize;

      // Compute the size of the box based on vSize
      float boxSize = vSize;

      // Compute the border size based on vSize
      float borderSize = mix(0.01, 0.001, boxSize);

      // Compute the color using box function
      float boxValue = box(uv, vec2(boxSize), borderSize);
      vec3 color = mix(vec3(0.1, 0.1, 0.1), vec3(0.2, 0.4, 0.6), boxValue);

      // Add some shininess
      float shine = smoothstep(0.0, 0.02, length(uv - 0.5)) * 0.5;
      color += vec3(shine);

      gl_FragColor = vec4(color, 1.0);
  }
`;

export default shader;
