/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef } from "react";
import { useFluidScrollSection } from "~hooks";

const FluidScrollSection = ({
  children,
  index,
  sectionRef,
  scrollRef,
  scrollTop = 0,
  onInView = () => {}
}) => {
  const groupRef = useRef();

  const section = useFluidScrollSection({
    index,
    sectionRef,
    scrollRef,
    groupRef,
    scrollTop
  });

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (section?.inView) {
      onInView();
    }
  }, [section.inView]);

  // ---------------------------------------------------------------------------
  // render

  return <group ref={groupRef}>{children({ section })}</group>;
};

export default FluidScrollSection;
