import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const SliceConfig = ({ children, config }) => {
  const { backgroundColor, textColor } = config || {};

  return (
    <div
      style={{
        color: textColor?.hex || `black`,
        backgroundColor: backgroundColor?.hex || `transparent`
      }}
      className={cn(styles.container)}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default SliceConfig;
