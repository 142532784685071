import React from "react";
import cn from "classnames";

import { Grid, SliceConfig } from "~components";

import { ReactComponent as CMCConnect } from "~assets/svg/cmc-connect.svg";
import { ReactComponent as CMCInvest } from "~assets/svg/cmc-invest.svg";
import { ReactComponent as CMCMarkets } from "~assets/svg/cmc-markets.svg";

import * as styles from "./styles.module.scss";

const HoverCards = ({ id, className, data: { cards, sliceConfig } }) => {
  let i;

  return (
    <section sliceid={id} className={cn(className, styles.container)}>
      <SliceConfig config={sliceConfig}>
        <Grid>
          {cards.map((hoverCard, index) => {
            const key = `hover-card-${index}`;

            const { hoverHex } = hoverCard;

            const hexStyle = {
              "--hoverHex": hoverHex || `#37375B`,
              "--svgHoverFill": hoverCard?.darkIcon ? `#050532` : `white`
            };

            let svg;

            if (hoverCard?.text?.toLowerCase()?.includes(`invest`)) {
              svg = <CMCInvest />;
            } else if (hoverCard?.text?.toLowerCase()?.includes(`markets`)) {
              svg = <CMCMarkets />;
            } else {
              svg = <CMCConnect />;
            }

            const innerJSX = (
              <>
                <figure className={cn(styles.cardImage)}>{svg}</figure>

                <p className={cn(styles.cardText, `h2`)}>{hoverCard.text}</p>
              </>
            );

            return (
              <article key={key} className={styles.card} style={hexStyle}>
                {(hoverCard?.url && (
                  <a
                    href={hoverCard.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {innerJSX}
                  </a>
                )) ||
                  innerJSX}
              </article>
            );
          })}
        </Grid>
      </SliceConfig>
    </section>
  );
};

export default HoverCards;
