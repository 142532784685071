// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--q2Gwi";
export var appearDown = "styles-module--appear-down--gJpbN";
export var appearLeft = "styles-module--appear-left--nnIoF";
export var appearRight = "styles-module--appear-right--wL3Tb";
export var appearUp = "styles-module--appear-up--3fgic";
export var container = "styles-module--container--G4BXL";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";