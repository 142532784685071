// extracted by mini-css-extract-plugin
export var container = "styles-module--container--pUA+q";
export var dark = "styles-module--dark--v0Wrg";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--IZV+J";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--fYglk";
export var listItem = "styles-module--listItem--CrSoH";
export var listItemAppear = "styles-module--list-item-appear--0Yowm";
export var listItemDisabled = "styles-module--listItemDisabled--atIW1";
export var listLabel = "styles-module--listLabel--bFWvY";
export var logo = "styles-module--logo--ZrCuf";
export var logoContainer = "styles-module--logoContainer--msZxf";
export var menu = "styles-module--menu--TbqDk";
export var menuContainer = "styles-module--menuContainer--Rg1rv";
export var menuIcon = "styles-module--menuIcon--pfgv5";
export var menuLine = "styles-module--menuLine--3MrJK";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var submenu = "styles-module--submenu--YUFM5";
export var submenuActive = "styles-module--submenuActive--HG-gn";
export var submenuContainer = "styles-module--submenuContainer--1bTRK";
export var submenuList = "styles-module--submenuList--7Q1Ti";
export var tablet = "769px";