// extracted by mini-css-extract-plugin
export var container = "styles-module--container--qlAal";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var list = "styles-module--list--OKmph";
export var listHeading = "styles-module--listHeading--3ZdLL";
export var listHeadingHeight = "styles-module--listHeadingHeight--keaRs";
export var listHeadingSize = "styles-module--listHeadingSize--8W2qx";
export var listHeadingSpacing = "styles-module--listHeadingSpacing--i1ieI";
export var listHeadingStyle = "styles-module--listHeadingStyle--h3Rlg";
export var listItem = "styles-module--listItem--4CVSI";
export var listItemAppear = "styles-module--list-item-appear--IZ6YN";
export var listItemHeight = "styles-module--listItemHeight--n6EMZ";
export var listItemSize = "styles-module--listItemSize--OfZ1E";
export var listItemSpacing = "styles-module--listItemSpacing--eXE0P";
export var listItemStyle = "styles-module--listItemStyle--ftmff";
export var listItemVisible = "styles-module--listItemVisible--4KlBW";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";