import * as Slices from "~slices";

export const useSlices = () => {
  const sliceIds = Object.keys(Slices);

  return {
    sliceIds,
    Slices
  };
};

export default useSlices;
