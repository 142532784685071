// extracted by mini-css-extract-plugin
export var card = "styles-module--card--+iy3U";
export var cardImage = "styles-module--cardImage--hKUH0";
export var cardText = "styles-module--cardText--jDqq-";
export var container = "styles-module--container--V0gO8";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";