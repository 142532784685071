import { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const useSanitySettings = () => {
  const { sanitySettings } = useStaticQuery(
    graphql`
      query Settings {
        sanitySettings {
          footer {
            active
          }

          menu {
            active
          }

          sidebar {
            activeColour {
              hex
              title
            }
            hoverColour {
              hex
              title
            }

            logo {
              asset {
                gatsbyImageData
                altText
              }
            }

            customCategoryOrder
          }
        }
      }
    `
  );

  const [settings, setSettings] = useState(null);

  // ----------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (settings) {
      return;
    }

    setSettings(sanitySettings);
  }, [sanitySettings]);

  // ----------------------------------------------------------------------

  return { settings };
};

export default useSanitySettings;
