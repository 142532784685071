import React from "react";
import cn from "classnames";
import { Grid, Image, SliceConfig } from "~components";

import * as styles from "./styles.module.scss";

const SimpleImage = ({ id, className, data: { full, image, sliceConfig } }) => {
  if (!image) {
    return null;
  }

  const fullBleed = full === true;

  const imageJSX = (
    <figure className={styles.figure}>
      <Image className={styles.image} image={image} />
    </figure>
  );

  return (
    <section sliceid={id} className={cn(className, styles.container)}>
      <SliceConfig config={sliceConfig}>
        {(!fullBleed && <Grid>{imageJSX}</Grid>) || imageJSX}
      </SliceConfig>
    </section>
  );
};

export default SimpleImage;
