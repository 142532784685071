import React from "react";
import cn from "classnames";
import { Grid, Image, SliceConfig } from "~components";

import * as styles from "./styles.module.scss";

const ImageGrid = ({ id, className, data: { images, sliceConfig } }) => {
  if (!images?.[0]) {
    return null;
  }

  return (
    <section sliceid={id} className={cn(className, styles.container)}>
      <SliceConfig config={sliceConfig}>
        <Grid>
          {images.map((image) => (
            <figure className={styles.figure}>
              <Image className={styles.image} image={image} />
            </figure>
          ))}
        </Grid>
      </SliceConfig>
    </section>
  );
};

export default ImageGrid;
