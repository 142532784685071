import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import { Grid, Link } from "~components";

import listBlocks from "~data/nav";
import { capitalizeString } from "~utils/helpers";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const LinkList = ({ id, className, data: { backgroundColor, fontColor } }) => {
  const rowsRef = useRef([]);

  const [visibleRows, setVisibleRows] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const visibleIds = entries
        .filter((entry) => entry.isIntersecting)
        .map((entry) => entry.target.id);
      setVisibleRows(visibleIds);
    });

    rowsRef.current.forEach((row) => {
      observer.observe(row);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section
      sliceid={id}
      className={cn(className, styles.container)}
      style={{
        background: backgroundColor?.hex || `transparent`,
        color: fontColor?.hex || `white`
      }}
    >
      <nav>
        {Object.keys(listBlocks).map((key) => {
          const list = listBlocks[key];

          return (
            <div className={cn(styles.listGroup)} key={`list-block-${key}`}>
              <Grid>
                <div className={cn(styles.separator)} />

                <header className={cn(styles.listGroupHeading)}>
                  <h4 className={cn(styles.listLabel, `h2`)}>
                    {capitalizeString(key.toLowerCase())}
                  </h4>
                </header>

                <ul className={cn(styles.listGroupItems)}>
                  {list.map(({ active, label, link }, index) => {
                    const [ref, inView] = useInView({
                      threshold: 0
                    });

                    const activeStyle = {
                      [styles.listGroupItemVisible]: inView
                    };
                    const style = active
                      ? cn(`h1`, styles.listGroupItem, activeStyle)
                      : cn(
                          `h1`,
                          styles.listGroupItem,
                          styles.listGroupItemDisabled,
                          activeStyle
                        );

                    return (
                      <li
                        ref={(el) => {
                          ref(el);
                          rowsRef.current[index] = el;
                        }}
                        key={`link-list-item-${index}`}
                        className={style}
                      >
                        <Link
                          to={link}
                          style={{ transitionDelay: `${index * 0.1}ms` }}
                        >
                          <span>{label}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </div>
          );
        })}
      </nav>
    </section>
  );
};

export default LinkList;
